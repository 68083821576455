<template>
  <div
    class="top-image overflow-hidden relative"
    v-if="src && srcset"
    :class="{ 'top-image--parallax': enableParallax }"
  >
    <img
      :src="src"
      :srcset="srcset"
      :sizes="$constants.images.sizes.top"
      :alt="alt"
      ref="topImage"
      data-rellax-speed="0.6"
    />
    <div v-if="gradientOverlay" class="top-image__overlay"></div>
  </div>
</template>

<script>
import parallaxScroll from "@/mixins/parallaxScroll";

export default {
  name: "LondoTopImage",
  props: {
    image: {
      type: [Object, Boolean],
      required: true,
    },
    gradientOverlay: {
      type: Boolean,
      default: true,
    },
    enableParallax: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parallaxRefs: ["topImage"],
    };
  },
  mixins: [parallaxScroll],
  computed: {
    srcset() {
      return this.image.srcset;
    },
    src() {
      return this.image.sizes.large;
    },
    alt() {
      return this.image.alt ? this.image.alt : "";
    },
  },
  mounted() {
    if (this.enableParallax) this.waitForParallaxRefs("topImage");
  },
};
</script>

<style scoped lang="scss">
.top-image {
  img {
  }
  &__overlay {
    @include overlay-top-image;
  }
}

@media screen and (orientation: portrait) {
  .top-image {
    img {
      width: 130%;
      margin-left: -15%;
      max-width: 130%;
    }
    &__overlay {
      @include overlay-top-image;
    }
  }
}

@media screen and (orientation: landscape) {
  .top-image {
    img {
      margin-top: 0vw;
    }
  }
}

@screen lg {
  .top-image {
    img {
      margin-top: -4vw;
    }
  }
}

@screen xl {
  .top-image {
    img {
      margin-top: -7vw;
    }
    &--parallax {
      img {
        padding-top: 3.8vh;
        margin-bottom: -3.9vh;
      }
    }
  }
}

@screen xxl {
  .top-image {
    img {
      margin-top: -7vw;
    }
    &--parallax {
      img {
        padding-top: 5vh;
        margin-bottom: -4.8vh;
      }
    }
  }
}
</style>
